<template>
  <v-card
    class="ma-5"
    :elevation="0"
    color="grey-lighten-4"
    flat
    outlined
    :loading="loading"
  >
    <v-toolbar color="primary" :dark="true">
      <v-toolbar-title>
        <span v-if="!empty(ballot_box)">
          {{ ballot_box.ballot_box_code }} - {{ ballot_box.ballot_box_name }}
        </span>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <div class="mt">
        <div class="col-lg-4 col-sm-12">
          <el-input
            v-model="searchIdCard"
            ref="id_search"
            placeholder="ID Card No"
            @keyup.enter.native="searchVoterIdCard"
          >
            <template slot="prepend">A-</template>
          </el-input>
        </div>
      </div>
      <div class="mt-2">
        <div class="col-lg-4 col-sm-12">
          <el-input
            v-model="searchSerial"
            ref="serial_search"
            :controls="false"
            placeholder="Serial Number"
            @keyup.enter.native="searchVoterSerial"
          ></el-input>
        </div>
      </div>
      <div class="mt-2 col-lg-4 col-sm-12">
        <div>
          <v-form ref="form" v-model="valid">
            <v-text-field
              v-model="id_card_no"
              :counter="10"
              :rules="idCardNoRules"
              :readonly="true"
              label="ID Card No"
              required
            ></v-text-field>

            <v-text-field
              v-model="name"
              :rules="nameRules"
              :readonly="true"
              label="Voter Name"
              required
            ></v-text-field>

            <v-text-field
              v-model="serial"
              :rules="serialRules"
              :readonly="true"
              label="Serial Number"
              required
            ></v-text-field>

            <v-btn
              :disabled="!valid"
              color="success"
              class="mr-4"
              @click="updateVoted"
            >
              UPDATE VOTED
            </v-btn>
          </v-form>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import Vue from "vue";
import {
  Button,
  Form,
  FormItem,
  Input,
  InputNumber,
  Message,
  Table,
  TableColumn,
} from "element-ui";
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Button);
Vue.prototype.$message = Message;
export default {
  props: {
    box: null,
  },
  created() {
    this.loginToBallotBox();
  },
  components: {},
  data() {
    return {
      valid: true,
      loading: false,
      boxes: [],
      campaigns: [],
      ballot_box: null,
      voters: [],
      filteredVoters: [],
      searchIdCard: null,
      searchSerial: null,
      searchName: null,
      voter: null,
      filter: null,
      id_card_no: "",
      idCardNoRules: [
        (v) => !!v || "ID Card No is required",
        (v) => (v && v.length == 7) || "ID Card Number must be 7 digits",
      ],
      name: "",
      nameRules: [(v) => !!v || "Name is required"],
      serial: "",
      serialRules: [(v) => !!v || "Serial Number is required"],
    };
  },
  methods: {
    loginToBallotBox() {
      let box = JSON.parse(localStorage.getItem("cm_my_box"));
      this.loading = true;
      this.api("public/login-ballot-box", box)
        .then((response) => {
          this.loading = false;
          if (response.status) {
            this.ballot_box = response.data;
            this.voters = response.data.voters;
            this.filteredVoters = response.data.voters;
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    searchVoterName() {
      if (this.empty(this.searchName)) {
        return;
      }
    },
    searchVoterSerial() {
      this.searchIdCard = null;
      if (this.empty(this.searchSerial)) {
        return;
      }
      let voter = this.voters.filter((e) => e.serial == this.searchSerial);
      if (!this.empty(voter)) {
        this.name = voter[0].full_name;
        this.id_card_no = voter[0].voter_id_card_no;
        this.serial = voter[0].serial;
        this.id = voter[0].id;
      } else {
        this.showError("Voter not registered to this Ballot Box");
      }
    },
    searchVoterIdCard() {
      this.searchSerial = null;
      if (this.empty(this.searchIdCard)) {
        return;
      }
      let voter = this.voters.filter(
        (e) =>
          e.voter_id_card_no.toLowerCase() ==
          "a" + this.searchIdCard.toLowerCase()
      );
      if (!this.empty(voter)) {
        this.name = voter[0].full_name;
        this.id_card_no = voter[0].voter_id_card_no;
        this.serial = voter[0].serial;
        this.id = voter[0].id;
      } else {
        this.showError("Voter not registered to this Ballot Box");
      }
    },
    filterVoters() {
      if (this.isNumeric(this.filter)) {
        this.filteredVoters = this.voters.filter(
          (e) => e.serial == this.filter
        );
      } else if (
        this.filter[0].toLowerCase() == "a" &&
        this.filter.length == 7 &&
        this.isNumeric(this.filter.substring(1, 6))
      ) {
        this.filteredVoters = this.voters.filter(
          (e) => e.voter_id_card_no.toLowerCase() == this.filter.toLowerCase()
        );
      } else {
        this.filteredVoters = this.voters.filter((e) =>
          e.full_name.toLowerCase().match(this.filter.toLowerCase())
        );
      }
    },
    clearFilter() {
      this.filteredVoters = this.voters;
    },
    updateVoted() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      this.loading = true;
      let f = {
        id_card_no: this.id_card_no,
        full_name: this.name,
        serial: this.serial,
        campaigns_id: this.ballot_box.campaigns_id,
        campaign_ballot_boxes_id: this.ballot_box.id,
        campaign_voters_id: this.id,
      };
      this.api("public/update-voted", f)
        .then((response) => {
          this.loading = false;
          if (response.status) {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            console.log(this.$refs);
            if (!this.empty(this.searchIdCard)) {
              this.$refs.id_search.focus();
            } else {
              this.$refs.serial_search.focus();
            }
            this.searchIdCard = "";
            this.searchSerial = "";
            this.showInfo("Voter Status updated", 500);
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    isNumeric(str) {
      if (typeof str != "string") return false; // we only process strings!
      return (
        !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
      ); // ...and ensure strings of whitespace fail
    },
  },
  name: "UpdatedVoters",
};
</script>
